import Vue from 'vue'
import VueRouter from 'vue-router'
import userModel from '@core/models/user'
import analytics from './analytics'
import invoices from './invoices'
import settings from './settings'
import avatars from './avatars'
import appearance from './appearance'
import audioMeditations from './audio-meditations'
import ai from './ai'
import geolocation from './geolocation'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: () => ({ name: 'users' }),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/Error403.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      noCheckAuth: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...analytics,
  ...settings,
  ...invoices,
  ...avatars,
  ...appearance,
  ...audioMeditations,
  ...ai,
  ...geolocation,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
// router.beforeEach((to, _, next) => {
// const userData = localStorage.getItem('userData')
router.beforeEach((to, from, next) => {
  if (!to.meta.hasOwnProperty('noCheckAuth') || to.meta.noCheckAuth !== true) {
    userModel(true).profile()
  }
  next()
})

// const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

// if (!canNavigate(to)) {
// Redirect to login if not logged in
// if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

// If logged in => not authorized
// return next({ name: 'misc-not-authorized' })

// return next({ name: 'misc-not-authorized' })
// }

// Redirect if logged in
// if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   next('/')
// }

// return next()
// })

export default router
