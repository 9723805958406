import { mdiPercentOutline } from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'ПОДПИСКИ',
    permission: PERMISSIONS.BILLING_READ,
  },
  {
    title: 'Активные подписки',
    icon: mdiPercentOutline,
    to: 'active-subscriptions',
    permission: PERMISSIONS.BILLING_READ,
  },
]
