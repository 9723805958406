
export default {
  namespaced: true,
  state: {
    profile: null,
    permissions: [],
  },
  getters: {
    hasPermission: state => permission => state.permissions.findIndex(value => value === permission) > -1,
  },
  mutations: {
    SET_PROFILE_MODEL(state, value) {
      state.profile = value
    },
    SET_PERMISSIONS(state, value) {
      state.permissions = value
    },
  },
  actions: {},
}
