import { mdiEarth } from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'GEO',
    permission: PERMISSIONS.CONTENT_READ,
  },
  {
    title: 'Страны',
    icon: mdiEarth,
    to: 'geolocation-countries',
    permission: PERMISSIONS.CONTENT_READ,
  },
]
