const PERMISSIONS = {
  ADMINS_READ: 'admins.read',
  ADMINS_WRITE: 'admins.write',
  USERS_READ: 'users.read',
  USERS_WRITE: 'users.write',
  BILLING_READ: 'billing.read',
  BILLING_WRITE: 'billing.write',
  CONTENT_READ: 'content.read',
  CONTENT_WRITE: 'content.write',
  STORIES_READ: 'stories.read',
  STORIES_WRITE: 'stories.write',
  TELEGRAM_MAILINGS_READ: 'telegram.mailings.read',
  TELEGRAM_MAILINGS_WRITE: 'telegram.mailings.write',
  AI_EXPORTS_READ: 'ai.exports.read',
}
export const ROLES = [
  {
    text: 'Администратор',
    value: 'admin',
  },
  {
    text: 'Поддержка',
    value: 'support',
  },
  {
    text: 'Коммерческий отдел',
    value: 'commerce',
  },
  {
    text: 'Менеджер',
    value: 'manager',
  },
  {
    text: 'Контент менеджер',
    value: 'content.manager',
  },
]
export default PERMISSIONS
