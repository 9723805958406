import {
  mdiAccountGroup,
  mdiSitemap,
  mdiSync,
} from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'АНАЛИТИКА',
  },
  {
    title: 'Пользователи',
    icon: mdiAccountGroup,
    to: 'users',
    permission: PERMISSIONS.USERS_READ,
  },
  {
    title: 'Workspaces',
    icon: mdiSitemap,
    to: 'workspaces',
  },
  {
    title: 'Интеграции',
    icon: mdiSync,
    to: 'analytics-integrations',
  },
]
