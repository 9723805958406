import { mdiPalette } from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'Аудио медитации',
    permission: PERMISSIONS.CONTENT_READ,
  },
  {
    title: 'Программы',
    icon: mdiPalette,
    to: 'audio-meditations-programs',
    permission: PERMISSIONS.CONTENT_READ,
  },
  {
    title: 'Темы',
    icon: mdiPalette,
    to: 'audio-meditations-topics',
    permission: PERMISSIONS.CONTENT_READ,
  },
  {
    title: 'Медитации',
    icon: mdiPalette,
    to: 'audio-meditations-meditations',
    permission: PERMISSIONS.CONTENT_READ,
  },
]
