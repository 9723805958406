// axios
import axios from 'axios'
import Vue from 'vue'
import ApiConfig from '@/apiConfig'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.defaults.withCredentials = true
axiosIns.defaults.baseURL = ApiConfig.apiUrl

// axiosIns.interceptors.request.use(
//   config => {
//     // Do something before request is sent
//
//     const accessToken = localStorage.getItem('accessToken')
//
//     // eslint-disable-next-line no-param-reassign
//     if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
//
//     return config
//   },
//   error => Promise.reject(error),
// )

axiosIns.interceptors.response.use(response => response, error => {
  const originalRequest = error.config
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    router.push('/login')
  }
  if (error.response && error.response.status === 403) {
    router.push('/error-403')
  }

  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
