import {
  mdiPercentOutline, mdiEmail, mdiAccount, mdiVideoAccount,
} from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'НАСТРОЙКИ',
  },
  {
    title: 'Промокоды',
    icon: mdiPercentOutline,
    to: 'settings-promo-codes',
    permission: PERMISSIONS.BILLING_READ,
  },
  {
    title: 'Админы',
    icon: mdiAccount,
    to: 'settings-admins',
    permission: PERMISSIONS.ADMINS_READ,
  },
  {
    title: 'Сторисы',
    icon: mdiVideoAccount,
    to: 'help-stories',
    permission: PERMISSIONS.STORIES_READ,
  },
  {
    title: 'Рассылки Telegram',
    icon: mdiEmail,
    to: 'settings-telegram-mailings',
    permission: PERMISSIONS.TELEGRAM_MAILINGS_READ,
  },
]
