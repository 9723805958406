import { mdiPalette } from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'Appearance',
    permission: PERMISSIONS.CONTENT_READ,
  },
  {
    title: 'Backgrounds',
    icon: mdiPalette,
    to: 'appearance-backgrounds',
    permission: PERMISSIONS.CONTENT_READ,
  },
]
